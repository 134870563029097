import { createAPI } from "../utils/request"

export const Login = data => createAPI('/acl/user/login', 'post', data) //登录

export const Logout = data => createAPI('acl/user/logout', 'get', data) // 退出

export const GetMenu = data => createAPI('acl/permission/menu', 'get', data) // 菜单接口

export const Editpassword = data => createAPI('acl/user/password/edit', 'post', data) // 修改密码接口

export const wechatLogin = data => createAPI('/acl/wechat/qrUrl', 'get', data) //微信扫码登录组装url

export const loginwx = data => createAPI('/acl/wechat/qrUserInfo', 'get', data) //微信扫码登录组装url

export const getSmsCode = data => createAPI(`/acl/user/smsCode`, 'get', data) //获取短信验证码

export const bindphone = data => createAPI(`/acl/user/bindPhone`, 'get', data) //绑定手机号

export const userOrderview = data => createAPI(`/order/userOrder/view`, 'get', data) //输入提取码查看 重建结果

export const fetchNoReadNum = data => createAPI(`/order/message/fetchNoReadNum`, 'get', data) //查询未读消息数

export const fetchNoReadMessages = data => createAPI(`/order/message/fetchNoReadMessages`, 'get', data) //查询未读消息内容

export const fetchReadMessages = data => createAPI(`/order/message/fetchReadMessages`, 'get', data) //获取已读消息

export const markMessageRead = data => createAPI(`/order/message/markMessageRead/${data.logId}`, 'get', data) //已读一个信息

export const messageDelete = data => createAPI(`/order/message/delete/${data.logId}`, 'post', data) //删除单个消息

export const deleteAllMessage = data => createAPI(`/order/message/deleteAllMessage`, 'post', data) //删除所有消息

export const markAllMessageWithRead = data => createAPI(`/order/message/markAllMessageWithRead`, 'post', data) //标记所有消息为已读

export const userPersonal = data => createAPI(`/acl/user/personal`, 'get', data) //获取用户个人信息

export const userSaveNickName = data => createAPI(`/acl/user/saveNickName?nickName=${data.nickName}`, 'post', data) //修改用户昵称










