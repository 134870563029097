<template>
  <div>
    
  </div>
</template>
<script>
import { mapState } from "vuex"; //vuex获取数据
import { setItem } from '../utils/storage.js'
import jwt from "jsonwebtoken";
import { loginwx } from "@/api/Login.js";
import { notifys } from '@/api/components/Notify.js'

export default {
  data() {
    return {
      confirm: false,
    };
  },
  created() {
    this.login();
  },
  mounted() {
    console.log("code", this.codes);
  },
  computed: {
    ...mapState([
      "phoneconfirm", //绑定手机状态
    ]),
    codes() {
      return this.$route.query.code;
    },
  },
  methods: {
    async login() {
      console.log(this.$route.query.code);
      if (this.$route.query.code!='') {
        const { data: res } = await loginwx({ code: this.$route.query.code });
        console.log(res);
        if (res.code === 20000) {
          const firsttoken = res.data.token;
          const phone = jwt.decode(firsttoken)
          console.log(firsttoken);
          console.log(phone);
          if (phone.phoneNumber==undefined) {
            this.$router.push("/login");
            setItem('token', firsttoken)
            this.$store.commit('editphoneconfirm',true)
            this.$store.commit('getphonetoken',firsttoken)
            notifys('warning',`该用户尚未绑定手机号,请绑定手机号`)
          }else{
            notifys('positive','登录成功')
            setItem('token', firsttoken)
            this.$router.push('/home')
          }
        }else{
          notifys('negative',res.message)
          this.$router.push("/login");
        }
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
